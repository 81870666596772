import React, { useEffect, useRef } from "react"
import { CheckoutContext } from "./CheckoutContext"
import { useContext } from "react"
import OrderSummaryMobile from "./OrderSummaryMobile"
import InfoTooltip from "../../shared/InfoTooltip"
import { InputAdornment } from "@mui/material"
import { DetailsFieldsType } from "./defaultFormContent"
import {
  useMediaQuery,
  useTheme,
  styled,
  TextField,
  MenuItem,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { useTranslation } from "react-i18next"

export type DetailsProps = {
  content: DetailsFieldsType
  showDateOfBirth: boolean
  focus: {
    counter: number
    key: string
  }
  setContent: (content: DetailsFieldsType) => void
}

const StyledTextField = styled(TextField)({
  marginBottom: "1.875rem",
})

export default function Details({
  content,
  showDateOfBirth,
  focus,
  setContent,
}: DetailsProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { products, monthlyGrossAmount, term, monthlyPrice } =
    useContext(CheckoutContext)

  const { t, ready } = useTranslation(["constants", "checkoutPage"])
  const TITLES = t("constants.TITLES", {
    returnObjects: true,
    useSuspense: false,
  })

  // TODO - fix eslint warnings/errors
  const refs = Object.keys(content).reduce(
    (a, v) => ({ ...a, [v]: useRef() }), // eslint-disable-line react-hooks/rules-of-hooks
    {}
  )

  // TODO - fix eslint warnings/errors
  useEffect(() => {
    refs[focus.key].current.focus()
  }, [focus.counter]) // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const finishedTextField = (key: string, field: any) => (
    <StyledTextField
      label={field.label}
      variant="outlined"
      value={field.value}
      inputRef={refs[key]}
      error={field.state === "error"}
      helperText={field.errorMessage}
      onChange={(e) =>
        setContent({
          ...content,
          [key]: {
            ...field,
            value:
              key === "niNumber"
                ? e.target.value.toUpperCase()
                : e.target.value,
          },
        })
      }
      onBlur={(e) => {
        const validation = field.validate(e.target.value)
        setContent({
          ...content,
          [key]: {
            ...field,
            state: validation.isValid ? undefined : "error",
            errorMessage: validation.isValid ? "" : validation.message,
          },
        })
      }}
      InputProps={{
        endAdornment: key === "niNumber" && (
          <InputAdornment position="end">
            <InfoTooltip
              content={t("checkoutPage:checkoutPage.tooltip.niNumber")}
            />
          </InputAdornment>
        ),
      }}
    />
  )

  if (!ready) {
    return null
  }

  return (
    <>
      {!isDesktop && (
        <OrderSummaryMobile
          products={products}
          monthlyReduction={monthlyGrossAmount}
          term={term}
          pricePerMonth={monthlyPrice}
        />
      )}
      <div className="fields">
        {Object.entries(content)
          .filter(([key]) => ["placeOfWork"].includes(key))
          .map(([key, field]) => (
            <React.Fragment key={key}>
              {finishedTextField(key, field)}
            </React.Fragment>
          ))}
        {Object.entries(content)
          .filter(([key]) => ["employeeNumber", "niNumber"].includes(key))
          .map(([key, field]) => (
            <React.Fragment key={key}>
              {finishedTextField(key, field)}
            </React.Fragment>
          ))}
        <div className="fields__selects">
          <TextField
            select
            SelectProps={{
              MenuProps: {
                BackdropProps: {
                  invisible: true,
                },
                disableScrollLock: true,
              },
            }}
            inputRef={refs.title}
            label={content.title.label}
            InputLabelProps={{
              htmlFor: content.title.labelFor,
            }}
            value={content.title.value}
            error={content.title.state === "error"}
            helperText={content.title.errorMessage}
            inputProps={{
              id: content.title.labelFor,
            }}
            onChange={(e) =>
              setContent({
                ...content,
                title: {
                  ...content.title,
                  value: e.target.value,
                },
              })
            }
            onBlur={(e) => {
              const validation = content.title.validate(e.target.value)
              setContent({
                ...content,
                title: {
                  ...content.title,
                  state: validation.isValid ? undefined : "error",
                  errorMessage: validation.isValid
                    ? ""
                    : validation.message || "",
                },
              })
            }}
            sx={{ width: "7rem", marginRight: "2.375rem" }}
          >
            {Object.values(TITLES).map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {showDateOfBirth && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                PopperProps={{ "data-cy": "date-picker" }}
                inputFormat="dd/MM/yyyy"
                label={content.dateOfBirth.label}
                value={content.dateOfBirth.value}
                inputRef={refs.dateOfBirth}
                onChange={(newValue, rawValue) => {
                  const validation = content.dateOfBirth.validate(
                    newValue?.toString() || "",
                    rawValue || ""
                  )
                  setContent({
                    ...content,
                    dateOfBirth: {
                      ...content.dateOfBirth,
                      value: newValue,
                      state: validation.isValid ? undefined : "error",
                      errorMessage: validation.isValid
                        ? ""
                        : validation.message || "",
                    },
                  })
                }}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "11rem" }}
                    error={content.dateOfBirth.state === "error"}
                    helperText={content.dateOfBirth.errorMessage}
                    onBlur={(e) => {
                      const validation = content.dateOfBirth.validate(
                        content.dateOfBirth.value?.toString() || "",
                        e.target.value
                      )
                      setContent({
                        ...content,
                        dateOfBirth: {
                          ...content.dateOfBirth,
                          inputValue: e.target.value,
                          state: validation.isValid ? undefined : "error",
                          errorMessage: validation.isValid
                            ? ""
                            : validation.message || "",
                        },
                      })
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        </div>
        {Object.entries(content)
          .filter(([key]) =>
            [
              "firstName",
              "lastName",
              "mobileNumber",
              "phoneNumber",
              "emailAddress",
            ].includes(key)
          )
          .map(([key, field]) => (
            <React.Fragment key={key}>
              {finishedTextField(key, field)}
            </React.Fragment>
          ))}
        <div className="fields__footnote">
          {t("checkoutPage:checkoutPage.form.requiredFields")}
        </div>
      </div>
    </>
  )
}
