import i18n from "../../../i18n"
import {
  validateEmail,
  validatePhoneNumber,
  validateMobileNumber,
  validatePostCode,
  validateName,
  validateNiNumber,
  validateDateOfBirth,
} from "../../../utils/validations"

export type FieldType = {
  label?: string
  labelFor?: string
  value: string
  state: undefined | "error" | "success"
  errorMessage: string
  validate: (value: string) => {
    isValid: boolean
    message?: string
  }
}

export type DetailsFieldsType = {
  placeOfWork: FieldType
  yourSalary?: FieldType
  employeeNumber: FieldType
  niNumber: FieldType
  title: FieldType
  dateOfBirth: {
    label?: string
    value: Date | null
    state: undefined | "error" | "success"
    errorMessage: string
    inputValue: string | null
    validate: (
      value: string,
      rawValue: string
    ) => { isValid: boolean; message?: string }
  }
  firstName: FieldType
  lastName: FieldType
  mobileNumber: FieldType
  phoneNumber: FieldType
  emailAddress: FieldType
}

export type DeliveryFieldsType = {
  lineOne: FieldType
  lineTwo: FieldType
  town: FieldType
  county: FieldType
  postCode: FieldType
}

export type FormContentType = {
  detailsFields: DetailsFieldsType
  deliveryFields: DeliveryFieldsType
}

export const defaultFormContent = (
  showYourSalaryField?: number | undefined | null
): FormContentType => {
  return {
    detailsFields: {
      placeOfWork: {
        label: i18n.t("checkoutPage:checkoutPage.placeOfWork"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => ({
          isValid: value.length > 0,
          message: i18n.t("checkoutPage:checkoutPage.enterPlaceOfWork"),
        }),
      },
      ...(showYourSalaryField && {
        yourSalary: {
          label: i18n.t("checkoutPage:checkoutPage.yourSalary"),
          value: "",
          state: undefined,
          errorMessage: "",
          validate: (value: string) => ({
            isValid: Number(value) >= 1000,
            message: i18n.t("checkoutPage:checkoutPage.enterYourSalary"),
          }),
        },
      }),
      employeeNumber: {
        label: i18n.t("checkoutPage:checkoutPage.employeeNumber"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => ({
          isValid: value.length > 0,
          message: i18n.t("checkoutPage:checkoutPage.enterEmployeeNumber"),
        }),
      },
      niNumber: {
        label: i18n.t("checkoutPage:checkoutPage.nationalInsuranceNumber"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validateNiNumber(value),
      },
      title: {
        label: i18n.t("checkoutPage:checkoutPage.title"),
        labelFor: "emp-title",
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => ({
          isValid: value.length > 0,
          message: i18n.t("checkoutPage:checkoutPage.selectTitle"),
        }),
      },
      dateOfBirth: {
        label: i18n.t("checkoutPage:checkoutPage.dateOfBirth"),
        value: null,
        state: undefined,
        errorMessage: "",
        inputValue: null,
        validate: (value: string, rawValue: string) =>
          validateDateOfBirth(value, rawValue),
      },
      firstName: {
        label: i18n.t("checkoutPage:checkoutPage.firstName"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validateName(value),
      },
      lastName: {
        label: i18n.t("checkoutPage:checkoutPage.lastName"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validateName(value),
      },
      mobileNumber: {
        label: i18n.t("checkoutPage:checkoutPage.mobileNumber"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validateMobileNumber(value),
      },
      phoneNumber: {
        label: i18n.t("checkoutPage:checkoutPage.phoneNumber"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validatePhoneNumber(value),
      },
      emailAddress: {
        label: i18n.t("checkoutPage:checkoutPage.emailAddress"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validateEmail(value),
      },
    },
    deliveryFields: {
      lineOne: {
        label: i18n.t("checkoutPage:checkoutPage.deliveryFields.lineOne"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => ({
          isValid: value.length > 0,
          message: i18n.t(
            "checkoutPage:checkoutPage.deliveryFields.lineOneMessage"
          ),
        }),
      },
      lineTwo: {
        label: i18n.t("checkoutPage:checkoutPage.deliveryFields.lineTwo"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: () => ({
          isValid: true,
        }),
      },
      town: {
        label: i18n.t("checkoutPage:checkoutPage.deliveryFields.town"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => ({
          isValid: value.length > 0,
          message: i18n.t(
            "checkoutPage:checkoutPage.deliveryFields.townMessage"
          ),
        }),
      },
      county: {
        label: i18n.t("checkoutPage:checkoutPage.deliveryFields.county"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => ({
          isValid: value.length > 0,
          message: i18n.t(
            "checkoutPage:checkoutPage.deliveryFields.countyMessage"
          ),
        }),
      },
      postCode: {
        label: i18n.t("checkoutPage:checkoutPage.deliveryFields.postCode"),
        value: "",
        state: undefined,
        errorMessage: "",
        validate: (value: string) => validatePostCode(value),
      },
    },
  }
}
