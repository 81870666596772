import { useDepartmentData, DepartmentContext } from "./DepartmentContext"
import RoundelGrid from "../../shared/RoundelGrid"
import DepartmentHeader from "../DepartmentHeader"
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs"
import { Grid, Container } from "@mui/material"
import Loader from "../../shared/Loader"
import { useContext, useEffect } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import BottomBanner from "../BottomBanner"
import { updateSalaryBracketModalState } from "../../../utils/apolloClient"
import { ColorStrip } from "../StoreHome"
import TopBenefitWindowInfo from "../TopBenefitWindowInfo"

export default function Department() {
  const { data } = useDepartmentData()
  const { supplierDesktopBanner, supplierMobileBanner, windowStatus } =
    useContext(StoreLayoutContext)

  useEffect(() => {
    updateSalaryBracketModalState("department")
  }, [])

  return (
    <DepartmentContext.Provider value={data}>
      {data.loading ? (
        <Loader />
      ) : (
        <>
          {windowStatus.status === "windowActive" && (
            <ColorStrip>
              <TopBenefitWindowInfo windowStatus={windowStatus} />
            </ColorStrip>
          )}
          <DepartmentHeader
            leftColour={data.leftColour}
            rightColour={data.rightColour}
            title={data.title}
          />
          <Container>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                margin: "1.0625rem auto",
                paddingLeft: "1.5rem",
              }}
            >
              <CustomBreadcrumbs breadcrumbsItems={data.breadcrumbs} />
            </Grid>
          </Container>
          <RoundelGrid
            roundels={data.categories}
            background="white"
            gtmActionName="navigation_departments_catogeries"
          />
          {(supplierMobileBanner || supplierDesktopBanner) && (
            <BottomBanner
              mobileBannerUrl={supplierMobileBanner}
              desktopBannerUrl={supplierDesktopBanner}
            />
          )}
        </>
      )}
    </DepartmentContext.Provider>
  )
}
