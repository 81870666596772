import { Box, Typography, css, styled } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import { useTranslation } from "react-i18next"
import { WindowStatusType } from "../layout/store/getSchemeStatus"

const TopBenefitWindowInfo = ({
  windowStatus,
}: {
  windowStatus: WindowStatusType
}) => {
  const { t, ready } = useTranslation("noOpenScheme")
  const isDateAvailable = windowStatus.status === "windowWillOpen"
  const isActive = windowStatus.status === "windowActive"

  if (!ready) {
    return null
  }
  return (
    <FlexContainer sx={isDateAvailable ? { width: "80%" } : undefined}>
      <StyledErrorIcon fontSize="small" />
      <InfoText>
        {isActive
          ? t("strip.activeSchemeMainText", {
              date: windowStatus.date?.end_date,
            })
          : isDateAvailable
          ? t("strip.textWithDate", { date: windowStatus.date?.start_date })
          : t("strip.textWithoutDate")}
      </InfoText>
    </FlexContainer>
  )
}

export default TopBenefitWindowInfo

export const InfoText = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.white.main};
    line-height: 1rem;
    font-size: 0.875rem;
    font-weight: bold;
    margin-left: 0.25rem;
    text-align: center;
    @media screen and (min-width: ${theme.breakpoints.values.md}px) {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  `}
`

export const StyledErrorIcon = styled(ErrorIcon)`
  color: white;
`

export const FlexContainer = styled(Box)`
  display: flex;
  width: 73%;
  align-items: center;
  justify-content: center;
`
