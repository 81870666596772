import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetGlobalLayoutDataQueryHookResult,
  useGetGlobalLayoutDataQuery,
  Noticeboard as NoticeboardType,
  HealthAndWellbeing as HealthAndWellbeingType,
  FamilyCare as FamilyCareType,
  GetGlobalMegaMenuDataQuery,
  useGetGlobalMegaMenuDataQuery,
  FamilyPay as FamilyPayType,
} from "../../graphqGenaretedTypes"
import formatMegaMenuData, { PortalMegaMenuItem } from "./formatMegaMenuData"
import formatBurgerMenuData from "./formatBurgerMenuData"
import { ItemHierarchyType } from "../shared/step-menu/StepMenu"
import { useTranslation } from "react-i18next"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
  fallbackLanguage,
} from "../../utils/languageUtils"

export type DummyMegaMenu = {
  label: string
  link: string
}[]

function getDummyMegaMenu(
  megaMenuLabels: DummyMegaMenu,
  showLsOnDummyMegaMenu: boolean,
  organisationId: string
): DummyMegaMenu {
  if (megaMenuLabels.length > 0) {
    const labels = megaMenuLabels.map((item) => item.label)

    if (showLsOnDummyMegaMenu) {
      const targetLabels: string[] = [
        "homeAndElectronics",
        "cycleToWork",
        "carBenefit",
      ]
      const lsItem: DummyMegaMenu[0] = {
        label: "lifestyleSavings",
        link: `/organisations/${organisationId}/employee/lifestyle_savings/landing`,
      }
      let lastTargetIndex = -1

      for (let i = 0; i < labels.length; i++) {
        if (targetLabels.includes(labels[i])) {
          lastTargetIndex = i
        }
      }

      const result: DummyMegaMenu = [...megaMenuLabels]

      if (lastTargetIndex !== -1) {
        result.splice(lastTargetIndex + 1, 0, lsItem)
      }
      return result
    }

    return megaMenuLabels
  }

  return []
}

export type GlobalLayoutContextData = {
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  organisationName: string
  currentUserId: number
  logo?: string
  condensedLogo?: string
  loading: boolean
  noticeboards: NoticeboardType[]
  backendUser: boolean
  reporter: boolean
  isOrganisationSettingUser: boolean
  employeeOrganisations: {
    name: string
    changePortalLink: string
  }[]
  availableLanguages: AvailableLanguagesType
  defaultLanguage: DefaultLanguageType
  dummyMegaMenu: DummyMegaMenu
  partnerJourney: string | undefined
}

const defaultValue: GlobalLayoutContextData = {
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  noticeboards: [],
  currentUserId: -1,
  backendUser: false,
  reporter: false,
  isOrganisationSettingUser: false,
  employeeOrganisations: [],
  organisationName: "Vivup",
  loading: true,
  availableLanguages: [],
  defaultLanguage: fallbackLanguage,
  dummyMegaMenu: [],
  partnerJourney: undefined,
}

let state = JSON.parse(JSON.stringify(defaultValue))

const valueFromQueryResult = function (
  result: GetGlobalLayoutDataQueryHookResult,
  organisationId: string
): GlobalLayoutContextData {
  const data = result.data

  const showLsOnDummyMegaMenu =
    (data?.employeeOrganisation?.lifestyleSavings?.categories &&
      data?.employeeOrganisation?.lifestyleSavings?.categories?.length > 0) ||
    false

  const megaMenuItems = data?.employeeOrganisation?.portal?.megaMenuItems

  let dummyMegaMenu: DummyMegaMenu = []

  if (megaMenuItems) {
    dummyMegaMenu = getDummyMegaMenu(
      megaMenuItems,
      showLsOnDummyMegaMenu,
      organisationId
    )
  }

  const loading = result.loading

  let privacyNoticeUrl = undefined
  if (organisationId) {
    privacyNoticeUrl =
      data?.employeeOrganisation?.customPrivacyNoticeUrl ??
      `/organisations/${organisationId}/privacy_notice`
  }

  if (!loading) {
    state = {
      ...defaultValue,
      privacyNoticeUrl: privacyNoticeUrl,
      organisationPrivacyNotice:
        data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
      organisationName: data?.employeeOrganisation?.name || "Vivup",
      currentUserId: data?.currentUser?.id || -1,
      logo: data?.employeeOrganisation?.logo || undefined,
      condensedLogo: data?.employeeOrganisation?.condensedLogo || undefined,
      noticeboards: data?.employeeOrganisation?.portal?.noticeboards || [],
      employeeOrganisations: data?.currentUser?.employeeOrganisations || [],
      backendUser: data?.currentUser?.backendUser || false,
      reporter: data?.employeeOrganisation?.reporter || false,
      isOrganisationSettingUser:
        data?.currentUser?.isOrganisationSettingUser || false,
      loading: loading,
      availableLanguages: data?.employeeOrganisation?.availableLanguages || [],
      defaultLanguage:
        data?.employeeOrganisation?.defaultLanguage || fallbackLanguage,
      dummyMegaMenu: dummyMegaMenu || [],
      partnerJourney: data?.employeeOrganisation?.partner?.platformType,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }
  return state
}

export const useGlobalLayoutData = function (): GlobalLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()

  const data = useGetGlobalLayoutDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  return valueFromQueryResult(data, params.organisationId ?? "")
}

export const GlobalLayoutContext = createContext(defaultValue)

type MegaMenuData = {
  megaMenuItems: PortalMegaMenuItem[]
  burgerMenuItems: ItemHierarchyType[]
  healthAndWellbeing: HealthAndWellbeingType
  familyCare: FamilyCareType
  familyPay: FamilyPayType
}

const valueFromMegaMenuQueryResult = (
  data: GetGlobalMegaMenuDataQuery,
  organisationId: string,
  t: (key: string) => string,
  ready: boolean
): MegaMenuData => {
  const healthAndWellbeingData = data.employeeOrganisation?.healthAndWellbeing
  const familycareData = data.employeeOrganisation?.familyCare
  const familyPay = data?.employeeOrganisation?.familyPay

  const megaMenuItems = formatMegaMenuData(
    data.employeeOrganisation?.portal?.megaMenuItems || [],
    data.employeeOrganisation?.lifestyleSavings?.categories || [],
    data.employeeOrganisation?.portal?.areas?.some(
      (e) => e === "lifestyle_savings"
    ),
    organisationId
  )

  const burgerMenuItems = ready
    ? formatBurgerMenuData(
        t,
        megaMenuItems,
        healthAndWellbeingData || undefined,
        familycareData || undefined,
        familyPay || undefined
      )
    : []

  const defaultHealthAndWellbeing: HealthAndWellbeingType = {
    level: "",
    usefulLinks: [],
  }

  const defaultFamilyCare: FamilyCareType = {
    usefulLinks: [],
  }

  const defaultFamilyPay: FamilyPayType = {
    usefulLinks: [],
  }

  return {
    megaMenuItems,
    burgerMenuItems,
    healthAndWellbeing: healthAndWellbeingData || defaultHealthAndWellbeing,
    familyCare: familycareData || defaultFamilyCare,
    familyPay: familyPay || defaultFamilyPay,
  }
}

export const useMenuData = (shouldFetch: boolean) => {
  const params = useParams()
  const { i18n, t, ready } = useTranslation("layout")

  const { data, loading, error } = useGetGlobalMegaMenuDataQuery({
    variables: {
      organisationId: params.organisationId ?? "",
      locale: i18n.language,
    },
    skip: !shouldFetch,
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  })

  let result: MegaMenuData | null = null
  if (data && !loading) {
    result = valueFromMegaMenuQueryResult(
      data,
      params.organisationId ?? "",
      t,
      ready
    )
  }

  return { data: result, loading, error }
}
