import { createContext } from "react"
import { useParams } from "react-router"
import { RoundelProps } from "../../shared/Roundel"
import {
  GetBasketDataQueryHookResult,
  useGetBasketDataQuery,
  useRemoveBasketItemMutation,
  useUpdateBasketItemMutation,
  useAddBasketItemMutation,
  BasketItem,
  BrochureGroup,
  useUpdateBasketItemsBrochureGroupMutation,
  Basket,
} from "../../../graphqGenaretedTypes"
import { useTranslation } from "react-i18next"

export type BasketContextData = {
  id: string
  monthlyPrice: number
  term: number
  monthlyGrossAmount: number
  disposalFee?: number
  endOfAgreementFee?: number
  products: BasketItem[]
  featuredCategories: RoundelProps[]
  loading: boolean
  totalSavings: number
  brochureGroups: BrochureGroup[]
  totalRrp: number
  brochureGroupId: number
  headroomValue?: number
  limitationMessage: Basket["limitationMessage"]
}

const defaultValue: BasketContextData = {
  id: "",
  monthlyPrice: 0,
  term: 0,
  monthlyGrossAmount: 0,
  disposalFee: undefined,
  endOfAgreementFee: undefined,
  products: [],
  featuredCategories: [],
  loading: true,
  totalSavings: 0,
  brochureGroups: [],
  totalRrp: 0,
  brochureGroupId: 0,
  headroomValue: undefined,
  limitationMessage: {},
}

let state = JSON.parse(JSON.stringify(defaultValue))

const queryDataToProducts: (
  queryData: GetBasketDataQueryHookResult
) => BasketItem[] = function (queryData) {
  let result: BasketItem[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.basket?.basketItems) {
    result = data.employeeOrganisation.scheme.basket.basketItems
  }

  return result
}

const queryDataToFeaturedCategories: (
  queryData: GetBasketDataQueryHookResult
) => RoundelProps[] = function (queryData) {
  let result: RoundelProps[] = []
  const data = queryData.data

  if (data?.employeeOrganisation?.scheme?.featuredCategories) {
    result = data?.employeeOrganisation?.scheme?.featuredCategories.map(
      (item) => {
        return {
          label: item.label || undefined,
          imageUrl: item.imageUrl,
          link: item.link,
          background: "gray",
        }
      }
    )
  }

  return result
}

// TODO - fix eslint warnings/errors
export const removeBasketItem = (basketItemId: string) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useRemoveBasketItemMutation({
    variables: {
      basketItemId: basketItemId,
    },
  })

// TODO - fix eslint warnings/errors
export const updateBasketItem = (basketItemId: string, quantity: number) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useUpdateBasketItemMutation({
    variables: {
      basketItemId: basketItemId,
      quantity: quantity,
    },
  })

// TODO - fix eslint warnings/errors
export const addBasketItem = (
  productTemplateId: number,
  bg: number,
  selectedSize: string
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const params = useParams()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useAddBasketItemMutation({
    variables: {
      bg: bg,
      productTemplateId: productTemplateId,
      selectedSize: selectedSize,
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
    },
  })
}

export const updateBasketItemsBrochureGroup = (
  basketId: string,
  brochureGroupId: string
) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useUpdateBasketItemsBrochureGroupMutation({
    variables: {
      basketId: basketId,
      brochureGroupId: brochureGroupId,
    },
  })

export const BasketContext = createContext(defaultValue)

export const useBasketData: () => {
  data: BasketContextData
} = function () {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetBasketDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  })

  if (!data.loading) {
    const products = queryDataToProducts(data)
    const featuredCategories = queryDataToFeaturedCategories(data)
    const monthlyPrice =
      data.data?.employeeOrganisation?.scheme?.basket?.monthlyPrice || 0
    const term = data.data?.employeeOrganisation?.scheme?.basket?.term || 0
    const monthlyGrossAmount =
      data.data?.employeeOrganisation?.scheme?.basket
        ?.totalMonthlyGrossAmount || 0
    const disposalFee =
      data.data?.employeeOrganisation?.scheme?.basket?.disposalFee
    const endOfAgreementFee =
      data.data?.employeeOrganisation?.scheme?.basket?.endOfAgreementFee
    const totalSavings =
      data.data?.employeeOrganisation?.scheme?.basket?.totalSavings
    const brochureGroups =
      data.data?.employeeOrganisation?.scheme?.brochureGroups
    const id = data.data?.employeeOrganisation?.scheme?.basket?.id
    const totalRrp = data.data?.employeeOrganisation?.scheme?.basket?.totalRrp
    const brochureGroupId =
      data.data?.employeeOrganisation?.scheme?.basket?.brochureGroupId
    const headroomValue =
      data.data?.employeeOrganisation?.scheme?.basket?.headroomValue
    const limitationMessage =
      data.data?.employeeOrganisation?.scheme?.basket?.limitationMessage || {}

    const loading = data.loading

    state = {
      ...defaultValue,
      id,
      products,
      monthlyPrice,
      term,
      monthlyGrossAmount,
      disposalFee,
      endOfAgreementFee,
      featuredCategories,
      totalSavings,
      brochureGroups,
      totalRrp,
      brochureGroupId,
      headroomValue,
      limitationMessage,
      loading,
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }

  return {
    data: state,
  }
}
