import { Grid, Container, Box, styled } from "@mui/material"
import { useProductData, ProductContext } from "./ProductContext"
import GalleryCarouselGrid from "../GalleryCarouselGrid"
import ModuleTabs from "../ModuleTabs"
import TabCenter from "../TabCenter"
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs"
import Loader from "../../shared/Loader"
import BottomBanner from "../BottomBanner"
import StickyACButton from "../../shared/affordability-calculator/StickyACButton"
import SavingsBanner from "../SavingsBanner"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useContext, useEffect } from "react"
import { updateSalaryBracketModalState } from "../../../utils/apolloClient"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { ColorStrip } from "../StoreHome"
import TopBenefitWindowInfo from "../TopBenefitWindowInfo"

export const Product = function () {
  const { data } = useProductData()
  const params = useParams()
  const { t, ready } = useTranslation("productDetail")
  const { windowStatus } = useContext(StoreLayoutContext)

  useEffect(() => {
    updateSalaryBracketModalState("product")
  }, [])

  return (
    <ProductContext.Provider value={data}>
      {data.loading || !ready ? (
        <Loader />
      ) : (
        <>
          {windowStatus.status === "windowActive" && (
            <ColorStrip>
              <TopBenefitWindowInfo windowStatus={windowStatus} />
            </ColorStrip>
          )}
          <Container>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                margin: { xs: "0", md: "1.0625rem auto" },
                paddingLeft: "1.5rem",
              }}
            >
              <Box
                sx={{
                  margin: {
                    xs: "1.25rem 0 0.75rem",
                    md: "1.375rem 0 2.25rem 0",
                  },
                }}
              >
                <CustomBreadcrumbs breadcrumbsItems={data.breadcrumbs} />
              </Box>
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={9.665}
              sx={{
                marginX: "auto",
              }}
            >
              <div className="topbar-container"></div>
              <GalleryCarouselGrid
                id={data.id}
                title={data.title}
                description={data.description}
                price={data.price}
                brand={data.brand}
                variant={data.variant}
                category={data.category}
                sku={data.sku}
                storeName={data.storeName}
                image={data.image}
                productImages={data.productImages}
                recommendations={data.recommendations}
                brochureGroupId={data.brochureGroupId}
                productId={data.id}
                availableSizes={data.availableSizes}
                totalAmount={data.totalAmount}
                isNhs={data.isNhs}
                productTagImage={data.productTagImage}
                department={data.department}
                brochureGroups={data.brochureGroups}
                supplierName={data.supplierName}
                releaseDate={data.releaseDate}
                status={data.status}
                reachedMaximumQuantity={data.reachedMaximumQuantity}
                maximumQuantity={data.maximumQuantity}
              />

              <ModuleTabs
                summary={data.summary}
                techSpecs={data.techSpecs}
                releaseDate={data.releaseDate}
                status={data.status}
                preBackorderMessage={data.preBackorderMessage}
              />
            </Grid>
            {params.schemeType === "cycle_to_work" && (
              <Disclaimer>
                <StyledParagraph>
                  {t("productDetail.Product.disclamer")}
                </StyledParagraph>
              </Disclaimer>
            )}
          </Container>
          <TabCenter
            products={data.recommendations}
            similarProducts={data.recommendationsSimilarProducts}
          />
          <StickyACButton />
          {data.savings ? (
            <SavingsBanner
              savings={data.savings}
              label={t("productDetail.SavingsBanner.label")}
              orderValue={data.rrp.toString()}
              brochureGroup={data.brochureGroupId.toString()}
            />
          ) : (
            <>
              {(data.supplierBanner?.desktop ||
                data.supplierBanner?.mobile) && (
                <BottomBanner
                  mobileBannerUrl={data.supplierBanner?.mobile}
                  desktopBannerUrl={data.supplierBanner?.desktop}
                />
              )}
            </>
          )}
        </>
      )}
    </ProductContext.Provider>
  )
}

const Disclaimer = styled("div")`
  margin: 0 auto 2.5rem auto;
  font-size: 12px;
  color: #35185f;
  line-height: 14px;
  text-align: center;
`

const StyledParagraph = styled("p")(
  ({ theme }) => `
  max-width: 317px;
  margin: auto;
  ${theme.breakpoints.up("sm")} {
    max-width: 783px;
  }
`
)
