import { Box, Grid, Container } from "@mui/material"
import NavigationLinks from "./sections/HeaderNavigationLinks"
import LogoSection from "./sections/Logosection"
import { Noticeboard } from "../../../graphqGenaretedTypes"
import { useEffect, useState } from "react"
import useIsMobileView from "../../../utils/useIsMobileView"
import { useTheme } from "@mui/material/styles"
import useScrollDirection from "../../../utils/useScrollDirection"
import { AlgoliaFilters } from "../store/StoreLayoutContext"
import SearchBoxWrapper from "../../shared/search-box/SearchBoxWrapper"
import LsSearchBox, {
  LsSearchBoxProps,
} from "../../shared/search-box/LsSearchBox"
import RegistrationHeaderNavigationLinks from "./sections/RegistrationHeaderNavigationLinks"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
} from "../../../utils/languageUtils"
import { useLocation } from "react-router-dom"

export type headerData = {
  MegaMenu?: JSX.Element
  BurgerMenu?: JSX.Element
  logo?: string
  organisationName?: string
  condensedLogo?: string
  currentUserId?: number
  showBasket: boolean
  lsSearchBoxProps?: {
    searchPath: LsSearchBoxProps["searchPath"]
    suggestions: LsSearchBoxProps["suggestions"]
    gtmEventType: LsSearchBoxProps["gtmEventType"]
  }
  showNoticeboard: Noticeboard[]
  employeeOrganisations?: {
    name: string
    changePortalLink: string
  }[]
  backendUser?: boolean
  reporter?: boolean
  isOrganisationSettingUser?: boolean
  algoliaFilters?: AlgoliaFilters
  isWidgetOpen?: boolean
  setIsWidgetOpen?: React.Dispatch<React.SetStateAction<boolean>>
  availableLanguages?: AvailableLanguagesType
  defaultLanguage?: DefaultLanguageType
  isSingleBenefit?: boolean
  invalidSingleBenefitScheme?: boolean
}

const Header = ({
  logo,
  organisationName,
  condensedLogo,
  currentUserId,
  MegaMenu,
  BurgerMenu,
  showBasket,
  lsSearchBoxProps,
  showNoticeboard,
  backendUser = false,
  reporter = false,
  isOrganisationSettingUser = false,
  employeeOrganisations,
  algoliaFilters,
  isWidgetOpen = false,
  setIsWidgetOpen,
  availableLanguages,
  defaultLanguage,
  isSingleBenefit,
  invalidSingleBenefitScheme = false,
}: headerData) => {
  const isMobile = useIsMobileView()
  const theme = useTheme()
  const scrollDirection = useScrollDirection()
  const [isVisible, setIsVisible] = useState(true)
  const location = useLocation()
  let registrationPage = window.location.pathname.includes("/sign_up")
  let confirmRegistrationPage =
    window.location.pathname.includes("/invitation/accept")
  let signInPage = window.location.pathname.includes("/sign_in")
  let changeYourPasswordPage =
    window.location.pathname.includes("/password/edit")
  let noNavigationLinksPages =
    window.location.pathname.includes("reg_") ||
    window.location.pathname.includes("confirmation_required") ||
    window.location.pathname.includes("approval_required")

  // on Chrome, zoom to 400%, scroll down hides the Header, scroll up shows the Header
  useEffect(() => {
    // window.devicePixelRatio changes when zoom with Chrome, not in other browsers
    if (window.devicePixelRatio >= 4) {
      setIsVisible(scrollDirection === "up")
    } else {
      setIsVisible(true)
    }
  }, [scrollDirection])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    registrationPage = window.location.pathname.includes("/sign_up")
    confirmRegistrationPage =
      window.location.pathname.includes("/invitation/accept")
    signInPage = window.location.pathname.includes("/sign_in")
    changeYourPasswordPage = window.location.pathname.includes("/password/edit")
    noNavigationLinksPages =
      window.location.pathname.includes("reg_") ||
      window.location.pathname.includes("confirmation_required") ||
      window.location.pathname.includes("approval_required")
  }, [location.pathname])
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleWidgetOpen = (e: React.FocusEvent<HTMLElement, Element>) => {
    if (
      e.target.id.includes("search") ||
      e.target.dataset?.element === "search"
    ) {
      if (setIsWidgetOpen) {
        setIsWidgetOpen(true)
      }
    } else {
      if (setIsWidgetOpen) {
        setIsWidgetOpen(false)
      }
    }
  }

  // click away from search elements (search box and algolia results)
  useEffect(() => {
    const handleClickOutsideSearchElements = (e: MouseEvent) => {
      const targetElement = e.target as HTMLElement

      if (
        !targetElement.id.includes("search") &&
        targetElement.dataset?.element !== "search"
      ) {
        if (setIsWidgetOpen) {
          setIsWidgetOpen(false)
        }
      }
    }

    document.addEventListener("click", handleClickOutsideSearchElements)

    return () => {
      document.removeEventListener("click", handleClickOutsideSearchElements)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const ifUserId = () => {
    if (!currentUserId && registrationPage) {
      return <RegistrationHeaderNavigationLinks BurgerMenu={BurgerMenu} />
    }
    if (changeYourPasswordPage) {
      return (
        <RegistrationHeaderNavigationLinks
          signInPage={!changeYourPasswordPage}
        />
      )
    }
    if ((!currentUserId && signInPage) || confirmRegistrationPage) {
      return (
        <RegistrationHeaderNavigationLinks
          BurgerMenu={BurgerMenu}
          signInPage={signInPage}
        />
      )
    } else if (currentUserId && !noNavigationLinksPages) {
      return (
        <NavigationLinks
          currentUserId={currentUserId}
          showBasket={showBasket}
          BurgerMenu={BurgerMenu}
          showNoticeboard={showNoticeboard}
          showChangePortal={backendUser}
          showReporting={reporter}
          isOrganisationSettingUser={isOrganisationSettingUser}
          employeeOrganisations={employeeOrganisations}
          availableLanguages={availableLanguages}
          defaultLanguage={defaultLanguage}
          isSingleBenefit={isSingleBenefit}
          invalidSingleBenefitScheme={invalidSingleBenefitScheme}
        />
      )
    }
  }
  return (
    <header
      style={!isVisible ? { display: "none" } : undefined}
      className="header-header"
      onFocus={(e: React.FocusEvent<HTMLElement, Element>) =>
        handleWidgetOpen(e)
      }
    >
      <Box
        className="Header"
        sx={{
          width: "100%",
          backgroundColor: theme.palette.white.main,
          color: theme.palette.primary.main,
          boxShadow: theme.boxShadow,
          position: "fixed",
          zIndex: 999,
          top: 0,
        }}
      >
        {isMobile ? (
          <>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                marginX: "auto",
                display: "flex",
                alignItems: "center",
                height: "4.375rem",
              }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LogoSection
                  invalidSingleBenefitScheme={invalidSingleBenefitScheme}
                  logo={logo}
                  condensedLogo={condensedLogo}
                  organisationName={organisationName}
                  isSingleBenefit={isSingleBenefit}
                />
              </Grid>
              <Grid
                item
                xs={8}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <nav>{ifUserId()}</nav>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              lg={10}
              sx={{
                marginX: "auto",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!invalidSingleBenefitScheme &&
                lsSearchBoxProps &&
                setIsWidgetOpen ? (
                  <LsSearchBox
                    {...lsSearchBoxProps}
                    isWidgetOpen={isWidgetOpen}
                    setIsWidgetOpen={setIsWidgetOpen}
                  />
                ) : (
                  !invalidSingleBenefitScheme &&
                  algoliaFilters &&
                  setIsWidgetOpen && (
                    <SearchBoxWrapper
                      isWidgetOpen={isWidgetOpen}
                      algoliaFilters={algoliaFilters}
                      setIsWidgetOpen={setIsWidgetOpen}
                    />
                  )
                )}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Container data-cy="desktop-header">
              <Grid
                container
                xs={12}
                lg={12}
                md={12}
                sx={{
                  marginX: "auto",
                }}
              >
                <Grid
                  item
                  xs={3}
                  lg={3}
                  md={2.8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LogoSection
                    invalidSingleBenefitScheme={invalidSingleBenefitScheme}
                    logo={logo}
                    condensedLogo={condensedLogo}
                    organisationName={organisationName}
                    isSingleBenefit={isSingleBenefit}
                  />
                </Grid>
                <Grid
                  item
                  xs={5.2}
                  lg={5}
                  md={4.2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!invalidSingleBenefitScheme &&
                  lsSearchBoxProps &&
                  setIsWidgetOpen ? (
                    <LsSearchBox
                      {...lsSearchBoxProps}
                      isWidgetOpen={isWidgetOpen}
                      setIsWidgetOpen={setIsWidgetOpen}
                    />
                  ) : (
                    !invalidSingleBenefitScheme &&
                    algoliaFilters &&
                    setIsWidgetOpen && (
                      <SearchBoxWrapper
                        isWidgetOpen={isWidgetOpen}
                        algoliaFilters={algoliaFilters}
                        setIsWidgetOpen={setIsWidgetOpen}
                      />
                    )
                  )}
                </Grid>
                <Grid
                  item
                  xs={3.8}
                  lg={4}
                  md={5}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <nav>{ifUserId()}</nav>
                </Grid>
              </Grid>
            </Container>
            {MegaMenu && (
              <Grid item container xs={12}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                  style={
                    isWidgetOpen && algoliaFilters
                      ? {
                          position: "absolute",
                          backgroundColor: "rgba(48, 37, 87, 0.39)",
                          height: "100vh",
                          width: "100%",
                          zIndex: "1000",
                          left: "0",
                        }
                      : undefined
                  }
                >
                  {MegaMenu}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </header>
  )
}

export default Header
