import { useMediaQuery, useTheme } from "@mui/material"
import { Box, Link } from "@mui/material"
import { useParams } from "react-router-dom"

export type LogosectionProps = {
  logo: string
  organisationName: string | undefined
  isSingleBenefit?: boolean
  invalidSingleBenefitScheme?: boolean
}

export default function Condensed_Logosection({
  logo,
  organisationName,
  isSingleBenefit,
  invalidSingleBenefitScheme,
}: LogosectionProps) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()

  const link = isSingleBenefit
    ? `/organisations/${params.organisationId}/employee/benefits/home_and_electronics/landing`
    : `/organisations/${params.organisationId}/employee/dashboard`

  return (
    <>
      <Box
        component="div"
        sx={{ height: "3.463rem", display: "table" }}
        data-cy="header-logo-condensed"
      >
        {!invalidSingleBenefitScheme && (
          <Link
            component={Link}
            href={link}
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <Box
              component="img"
              sx={{
                maxWidth: "7.625rem",
                maxHeight: isDesktop ? "3.125rem" : "2.737rem",
                paddingLeft: { xs: 3, sm: 3, md: 6 },
                paddingBottom: 1,
              }}
              alt={
                organisationName !== undefined
                  ? `${organisationName} logo`
                  : "Vivup logo"
              }
              src={logo}
            ></Box>
          </Link>
        )}
      </Box>
    </>
  )
}
