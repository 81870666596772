import { parseISO, isBefore, isAfter, isWithinInterval, format } from "date-fns"

type WindowStatus =
  | "noWindow"
  | "windowClosed"
  | "windowWillOpen"
  | "windowActive"

export interface WindowStatusType {
  status: WindowStatus
  date: null | {
    start_date?: string
    end_date?: string
  }
}

export const defaultWindowStatus: WindowStatusType = {
  status: "noWindow",
  date: null,
}

function getWindowStatus(
  start_date?: string | null,
  end_date?: string | null,
  isSingleBenefit?: boolean
): WindowStatusType {
  const currentDate = new Date(new Date().setHours(0, 0, 0, 0))

  // If start_date is in the future
  if (
    isSingleBenefit &&
    start_date &&
    isAfter(parseISO(start_date), currentDate)
  ) {
    return {
      status: "windowWillOpen",
      date: {
        start_date: format(parseISO(start_date), "dd/MM/yyyy"),
      },
    }
  }

  // If end_date is in the past
  if (
    isSingleBenefit &&
    end_date &&
    isBefore(parseISO(end_date), currentDate)
  ) {
    return {
      status: "windowClosed",
      date: null,
    }
  }

  // If current date is between start_date and end_date
  // show in store page
  if (
    start_date &&
    end_date &&
    isWithinInterval(currentDate, {
      start: parseISO(start_date),
      end: parseISO(end_date),
    })
  ) {
    return {
      status: "windowActive",
      date: {
        end_date: format(parseISO(end_date), "dd/MM/yyyy"),
      },
    }
  }

  // if the window is closed start_date and end_date are both null from backend
  return defaultWindowStatus
}

export default getWindowStatus
