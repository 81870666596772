import { createContext } from "react"
import { useParams } from "react-router-dom"
import {
  GetLayoutDataQueryHookResult,
  useGetLayoutDataQuery,
  SpecialPromotion,
} from "../../../graphqGenaretedTypes"
import { MegaMenuProps } from "../../shared/MegaMenu"
import { ItemHierarchyType } from "../../shared/step-menu/StepMenu"
import { SupportLinksProps } from "../../shared/store/SupportLinks"
import { useTranslation } from "react-i18next"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
  fallbackLanguage,
} from "../../../utils/languageUtils"
import { DummyMegaMenu } from "../GlobalLayoutContext"
import getWindowStatus, {
  WindowStatusType,
  defaultWindowStatus,
} from "./getSchemeStatus"
import { BannerCarouselProps } from "../../shared/banner-carousel/BannerCarousel"

export type AlgoliaFilters = {
  facetFilters: string[]
  filters: string
}

export type StoreLayoutContextData = {
  basketCount: number
  supplierDesktopBanner?: string
  supplierMobileBanner?: string
  supportLinks: SupportLinksProps["linkList"]
  schemeFaqUrl?: string
  privacyNoticeUrl?: string
  organisationPrivacyNotice: boolean
  currentUserId?: number
  organisationName: string
  logo?: string
  condensedLogo?: string
  footerLogo?: string
  departments: MegaMenuProps["departments"]
  departmentHierarchy: ItemHierarchyType[]
  loading: boolean
  weeksPerYear: number
  quoteType: string
  searchSuggestions: string[]
  specialPromotion: SpecialPromotion
  algoliaFilters: AlgoliaFilters
  availableLanguages: AvailableLanguagesType
  defaultLanguage: DefaultLanguageType
  dummyMegaMenu: DummyMegaMenu
  partnerJourney: string | undefined
  isSingleBenefit: boolean
  invalidSingleBenefitScheme: boolean
  windowStatus: WindowStatusType
  banners: BannerCarouselProps[]
  backendUser: boolean
  employeeOrganisations: {
    name: string
    changePortalLink: string
  }[]
}

const defaultValue: StoreLayoutContextData = {
  basketCount: 0,
  privacyNoticeUrl: undefined,
  organisationPrivacyNotice: false,
  organisationName: "Vivup",
  departments: [],
  departmentHierarchy: [],
  supportLinks: [],
  loading: true,
  weeksPerYear: 0,
  quoteType: "",
  searchSuggestions: [],
  specialPromotion: {
    url: "",
    mobilePopUpUrl: "",
    desktopPopUpUrl: "",
  },
  algoliaFilters: {
    facetFilters: [],
    filters: "",
  },
  availableLanguages: [],
  defaultLanguage: fallbackLanguage,
  dummyMegaMenu: [],
  partnerJourney: undefined,
  isSingleBenefit: false,
  invalidSingleBenefitScheme: false,
  windowStatus: defaultWindowStatus,
  banners: [],
  backendUser: false,
  employeeOrganisations: [],
}

let state = JSON.parse(JSON.stringify(defaultValue))

const valueFromQueryResult = function (
  result: GetLayoutDataQueryHookResult,
  organisationId?: string,
  supplierBanner?: {
    mobile?: string
    desktop?: string
  }
): StoreLayoutContextData {
  const data = result.data

  const dummyMegaMenu =
    data?.employeeOrganisation?.scheme?.store?.departments.map(
      ({ name, link }) => ({
        label: name,
        link,
      })
    )

  const loading = result.loading

  const faqSupportLink = data?.employeeOrganisation?.scheme?.supportLinks?.find(
    (supportLink) => supportLink.label === "FAQs"
  )
  const schemeFaqUrl = faqSupportLink ? faqSupportLink.url : undefined

  let privacyNoticeUrl = undefined
  if (organisationId) {
    privacyNoticeUrl =
      data?.employeeOrganisation?.customPrivacyNoticeUrl ??
      `/organisations/${organisationId}/privacy_notice`
  }

  if (!loading) {
    const isSingleBenefit =
      data?.employeeOrganisation?.partner?.platformType === "single_benefit"

    const windowStatus = getWindowStatus(
      data?.employeeOrganisation?.scheme?.windowStartDate,
      data?.employeeOrganisation?.scheme?.windowEndDate,
      isSingleBenefit
    )

    const invalidSingleBenefitScheme =
      (isSingleBenefit && windowStatus.status === "windowWillOpen") ||
      (isSingleBenefit && windowStatus.status === "windowClosed")

    const banners = data?.employeeOrganisation?.scheme?.banners
      ? data?.employeeOrganisation?.scheme?.banners.map((item) => {
          return {
            desktopImageUrl: item.desktopImageUrl,
            mobileImageUrl: item.mobileImageUrl,
            link: item.link,
            title: item?.title || undefined,
            subtitle: item?.subtitle || undefined,
          }
        })
      : []

    state = {
      ...defaultValue,
      basketCount:
        data?.employeeOrganisation?.scheme?.basket?.count ??
        defaultValue.basketCount,
      supplierDesktopBanner:
        (supplierBanner?.desktop ||
          data?.employeeOrganisation?.scheme?.supplierDesktopBanner) ??
        undefined,
      supplierMobileBanner:
        (supplierBanner?.mobile ||
          data?.employeeOrganisation?.scheme?.supplierMobileBanner) ??
        undefined,
      schemeFaqUrl: schemeFaqUrl ?? undefined,
      privacyNoticeUrl: privacyNoticeUrl,
      organisationPrivacyNotice:
        data?.employeeOrganisation?.organisationPrivacyNotice ?? false,
      currentUserId: data?.currentUser?.id,
      organisationName: data?.employeeOrganisation?.name || "Vivup",
      logo: data?.employeeOrganisation?.logo || undefined,
      condensedLogo: data?.employeeOrganisation?.condensedLogo || undefined,
      footerLogo: data?.employeeOrganisation?.footerLogo || undefined,
      departments: [], // TODO - To Remove at some point
      departmentHierarchy: [], // TODO - To Remove at some point
      supportLinks: data?.employeeOrganisation?.scheme?.supportLinks || [],
      loading: loading,
      weeksPerYear: data?.employeeOrganisation?.scheme?.weeksPerYear || 0,
      quoteType: data?.employeeOrganisation?.scheme?.quoteType || "",
      searchSuggestions: [], // TODO - To Remove at some point
      specialPromotion: {
        url: "",
        mobilePopUpUrl: "",
        desktopPopUpUrl: "",
      },
      algoliaFilters: data?.employeeOrganisation?.scheme?.store
        ?.algoliaFilters || {
        facetFilters: [],
        filters: "",
      },
      availableLanguages: data?.employeeOrganisation?.availableLanguages || [],
      defaultLanguage:
        data?.employeeOrganisation?.defaultLanguage || fallbackLanguage,
      dummyMegaMenu: dummyMegaMenu || [],
      partnerJourney: data?.employeeOrganisation?.partner?.platformType,
      isSingleBenefit,
      invalidSingleBenefitScheme,
      windowStatus,
      banners,
      backendUser: data?.currentUser?.backendUser || false,
      employeeOrganisations: data?.currentUser?.employeeOrganisations || [],
    }
  } else {
    state = {
      ...state,
      loading: true,
    }
  }
  return state
}

export const useStoreLayoutData = function (supplierBanner?: {
  mobile?: string
  desktop?: string
}): StoreLayoutContextData {
  const params = useParams()
  const { i18n } = useTranslation()
  const data = useGetLayoutDataQuery({
    variables: {
      // TODO - handle empty params gracefully
      organisationId: params.organisationId ?? "",
      schemeType: params.schemeType ?? "",
      locale: i18n.language,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  })

  return valueFromQueryResult(data, params.organisationId, supplierBanner)
}

export const StoreLayoutContext = createContext(defaultValue)
