import PersonIcon from "@mui/icons-material/Person"
import AssignmentIcon from "@mui/icons-material/Assignment"
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket"
import LanguageIcon from "@mui/icons-material/Language"
import NavItem from "../../../shared/nav/Item"
import { useLocation, useParams } from "react-router"
import { StoreLayoutContext } from "../../store/StoreLayoutContext"
import { useContext, useEffect, useState } from "react"
import { Noticeboard } from "../../../../graphqGenaretedTypes"
import useIsMobileView from "../../../../utils/useIsMobileView"

import i18n from "../../../../i18n"
import { useTranslation } from "react-i18next"
import isTranslatedPath from "../../../../utils/isTranslatedPath"
import {
  AvailableLanguagesType,
  DefaultLanguageType,
  getLanguageCode,
  getLngCookie,
  setLanguageCookie,
  getLanguageToRenderOnMount,
  getBooleanFromLocalStorage,
} from "../../../../utils/languageUtils"

export type HeaderNavigationLinksProps = {
  currentUserId: number
  showBasket: boolean
  BurgerMenu?: JSX.Element
  showNoticeboard: Noticeboard[]
  employeeOrganisations?: {
    name: string
    changePortalLink: string
  }[]
  showChangePortal: boolean
  showReporting: boolean
  isOrganisationSettingUser: boolean
  availableLanguages?: AvailableLanguagesType
  defaultLanguage?: DefaultLanguageType
  isSingleBenefit?: boolean
  invalidSingleBenefitScheme?: boolean
}

const HeaderNavigationLinks = ({
  showBasket,
  currentUserId,
  BurgerMenu,
  showNoticeboard,
  employeeOrganisations,
  showChangePortal,
  showReporting,
  isOrganisationSettingUser,
  availableLanguages,
  defaultLanguage,
  isSingleBenefit,
  invalidSingleBenefitScheme,
}: HeaderNavigationLinksProps) => {
  const params = useParams()

  const { basketCount, schemeFaqUrl } = useContext(StoreLayoutContext)
  const { t, ready } = useTranslation("layout")
  const location = useLocation()

  let levels = 1

  const basketLabel = (basketCount: number) =>
    basketCount === 0
      ? t("header.basket")
      : `${t("header.basket")} (${basketCount})`

  let myAccountOptions: {
    label: string
    link?: string
    hide?: boolean
    external?: boolean
    children?: { label: string; link: string }[]
  }[] = [
    { label: t("header.profile"), link: `/users/${currentUserId}/edit` },
    { label: t("header.orders"), link: `/users/${currentUserId}/orders` },
    {
      label: t("header.messages"),
      link: `/organisations/${params.organisationId}/employee/messages`,
    },
  ]

  if (isOrganisationSettingUser) {
    myAccountOptions = myAccountOptions.concat([
      {
        label: t("header.settings"),
        link: `/organisations/${params.organisationId}/settings`,
      },
    ])
  }

  if (schemeFaqUrl) {
    myAccountOptions = myAccountOptions.concat([
      {
        label: t("header.faqs"),
        link: schemeFaqUrl ?? "",
        external: true,
      },
    ])
  }

  if (!window.location.pathname.includes("/dashboard") && !isSingleBenefit) {
    myAccountOptions = myAccountOptions.concat([
      { label: t("header.backToPortal"), link: "/" },
    ])
  }

  if (showReporting && !isSingleBenefit) {
    myAccountOptions = myAccountOptions.concat([
      {
        label: "MI Reporting",
        link: `/organisations/${params.organisationId}/analytics`,
      },
    ])
  }

  if (showChangePortal) {
    myAccountOptions = myAccountOptions.concat([
      {
        label: t("header.changeOrganisation"),
        link: `/users/${currentUserId}/change_portal`,
      },
    ])
  } else if (employeeOrganisations && employeeOrganisations.length > 1) {
    levels = 2
    myAccountOptions = myAccountOptions.concat([
      {
        label: t("header.yourOrganisation"),
        children: employeeOrganisations.map((organisation) => ({
          label: organisation.name,
          link: organisation.changePortalLink,
        })),
      },
    ])
  }

  myAccountOptions = myAccountOptions.concat([
    { label: t("header.signOut"), link: "/users/sign_out" },
  ])

  //  language selection part begins here

  const showLanguageSelection =
    (availableLanguages && availableLanguages.length > 1) || false

  const isMobile = useIsMobileView()

  const [language, setLanguage] = useState<"en-GB" | "cy">("en-GB")

  // when mount, check if user has toggled language before, if yes, use that language over default language saved in backend
  useEffect(() => {
    const isLngToggled = getBooleanFromLocalStorage("isLngToggled")
    const userPreferredLng = getLngCookie()

    if (availableLanguages?.length == 0) {
      return
    }

    const languageToRenderOnMount = getLanguageToRenderOnMount(
      isLngToggled,
      userPreferredLng,
      defaultLanguage,
      availableLanguages
    )

    if (languageToRenderOnMount) {
      setLanguage(languageToRenderOnMount as "en-GB" | "cy")
      i18n.changeLanguage(languageToRenderOnMount)
    }
    // eslint-disable-next-line
  }, [availableLanguages?.length])

  const changeUiLanguage = (newLanguage: "en-GB" | "cy") => {
    if (newLanguage !== language) {
      setLanguage(newLanguage)
      i18n.changeLanguage(newLanguage)
      localStorage.setItem("isLngToggled", JSON.stringify(true))
      localStorage.setItem("prefLng", newLanguage)
      setLanguageCookie(newLanguage)
    }
  }

  const disableLanguageSelection = !isTranslatedPath(location.pathname)

  if (!ready) {
    return null
  }
  return (
    <>
      {!invalidSingleBenefitScheme &&
        showLanguageSelection &&
        availableLanguages &&
        availableLanguages.length > 1 && (
          <NavItem
            name={
              isMobile
                ? t("menu.languageSelection")
                : getLanguageCode(
                    availableLanguages && availableLanguages,
                    i18n.language
                  )
            }
            title={t("menu.languageSelection")}
            icon={<LanguageIcon />}
            size="small"
            listItemHierarchy={
              availableLanguages &&
              availableLanguages.map(({ name }, index) => ({
                key: `language-selection-${index}`,
                name: name,
              }))
            }
            changeUiLanguage={changeUiLanguage}
            disabled={disableLanguageSelection}
            tooltip={
              disableLanguageSelection
                ? "Language setting unavailable on this page"
                : ""
            }
          />
        )}
      {showBasket && (
        <NavItem
          name={basketLabel(basketCount)}
          rightLabel={basketCount > 0 ? `(${basketCount})` : ""}
          icon={<ShoppingBasketIcon />}
          link={`/organisations/${params.organisationId}/employee/benefits/${params.schemeType}/basket`}
          title={t("header.basket")}
          size="small"
        />
      )}
      {showNoticeboard?.length > 0 && (
        <NavItem
          name={t("header.noticeboard")}
          title={t("header.noticeboard")}
          icon={<AssignmentIcon />}
          listItemHierarchy={showNoticeboard.map((item, index) => ({
            key: `noticeboard-${index}`,
            name: item.label,
            link: item.link,
            hide: false,
            external: true,
          }))}
          size="medium"
          triggerGtmEvent={true}
        />
      )}
      <NavItem
        name={t("header.myAccount")}
        title={t("header.myAccount")}
        icon={<PersonIcon />}
        listItemHierarchy={myAccountOptions.map((item, index) => ({
          key: `my-account-option-${index}`,
          name: item.label,
          link: item.link,
          hide: false,
          external: true,
          hasChild: item.children !== undefined,
          children:
            item.children === undefined
              ? undefined
              : item.children.map((child) => ({
                  name: child.label,
                  link: child.link,
                })),
        }))}
        size="small"
        levels={levels}
      />
      {BurgerMenu}
    </>
  )
}

export default HeaderNavigationLinks
