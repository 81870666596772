import { Close } from "@mui/icons-material"
import {
  Box,
  Button,
  Modal as MuiModal,
  SxProps,
  css,
  styled,
} from "@mui/material"
import { useTranslation } from "react-i18next"

export default function Modal({
  open,
  handleClose,
  children,
  sx,
  showCloseIcon = true,
  showCloseWindowButton = true,
}: {
  open: boolean
  handleClose: () => void
  children: JSX.Element
  sx?: SxProps
  showCloseIcon?: boolean
  showCloseWindowButton?: boolean
}) {
  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }

  return (
    <MuiModal sx={sx} open={open} onClose={() => handleClose()}>
      <MainBox
        id="modal-main-box"
        sx={{
          width: {
            xs: "80%",
            md: "32rem",
          },
        }}
      >
        {showCloseIcon && (
          <Button
            onClick={() => handleClose()}
            sx={{ alignSelf: "flex-end" }}
            title="Close"
          >
            <Close />
          </Button>
        )}
        {children}
        {showCloseWindowButton && (
          <CloseWindowButton
            id="close-window-btn"
            variant="invisible"
            onClick={() => handleClose()}
            className="modal-close-window-button"
          >
            {t("exploreYourBenefit.buttons.closeWindow")}
          </CloseWindowButton>
        )}
      </MainBox>
    </MuiModal>
  )
}

export const MainBox = styled(Box)(
  ({ theme }) => css`
    & {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 85vh;
      overflow: auto;
      background: ${theme.palette.white.main};
      border-radius: 1rem;
      padding-top: 1.25rem;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: column;
      outline: none;
    }
  `
)

export const CloseWindowButton = styled(Button)(
  ({ theme }) => css`
    & {
      color: ${theme.palette.grey[700]};
      text-decoration: underline;
      align-self: center;
      padding-right: 0.75rem; // there's no padding-y attribute in ordinary css
      padding-left: 0.75rem;
      &:hover {
        text-decoration: underline;
      }
    }
  `
)
