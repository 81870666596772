import { useTranslation } from "react-i18next"
import { css, styled } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import {
  InfoText as Text,
  FlexContainer as Container,
} from "./TopBenefitWindowInfo"
import { WindowStatusType } from "../layout/store/getSchemeStatus"

const BottomBenefitWindowInfo = ({
  windowStatus,
}: {
  windowStatus: WindowStatusType
}) => {
  const { t, ready } = useTranslation("noOpenScheme")
  const isDateAvailable = windowStatus.status === "windowWillOpen"

  if (!ready) {
    return null
  }
  return (
    <FlexContainer>
      <StyledErrorIcon fontSize="small" />
      <InfoText>
        {isDateAvailable
          ? t("strip.textWithDate", { date: windowStatus.date?.start_date })
          : t("strip.textWithoutDate")}
      </InfoText>
    </FlexContainer>
  )
}

export default BottomBenefitWindowInfo

const FlexContainer = styled(Container)`
  margin: auto;
  width: 90%;
`

const StyledErrorIcon = styled(ErrorIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
  `}
`

const InfoText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.palette.error.main};
  `}
`
