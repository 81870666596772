import { useMediaQuery, useTheme } from "@mui/material"
import Container from "@mui/material/Container"
import Sidebar from "../shared/store/Sidebar"
import ProductCardGrid from "./ProductCardGrid"
import { useStoreData, StoreContext } from "./StoreContext"
import ScrollToTop from "../shared/ScrollToTop"
import StickyACButton from "../shared/affordability-calculator/StickyACButton"
import classNames from "classnames"
import Topbar from "../shared/store/topbar/Topbar"
import { useContext, useEffect } from "react"
import { StoreLayoutContext } from "../layout/store/StoreLayoutContext"
import BottomBanner from "./BottomBanner"
// import { StoreDepartmentContext } from "../layout/store/StoreDepartmentContext"
// import StickyPromoPopup from "../shared/StickyPromoPopup"
// import { specialPromotionAvailable } from "../../utils/specialPromoHelper"
import CycleToWorkMessage from "./cycle-to-work/CycleToWorkMessage"
import { useParams } from "react-router-dom"
import {
  gtmOnPageland,
  gtmPlacedStore,
} from "../shared/gtm-events/CustomGtmEvents"
import { updateSalaryBracketModalState } from "../../utils/apolloClient"
import { ColorStrip } from "./StoreHome"
import TopBenefitWindowInfo from "./TopBenefitWindowInfo"

export const Store = function () {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const { data } = useStoreData()
  // const { specialPromotion } = useContext(StoreDepartmentContext)
  const {
    supplierDesktopBanner,
    supplierMobileBanner,
    currentUserId,
    windowStatus,
  } = useContext(StoreLayoutContext)
  const params = useParams()
  const store = params.schemeType
  const CycletoWorkStore = params.schemeType === "cycle_to_work"
  const currentOrg = params.organisationId
  useEffect(() => {
    if (data.loading) {
      gtmPlacedStore(store)
      window.localStorage.setItem("customerUserId", currentUserId)
      window.localStorage.setItem("currentOrg", currentOrg)
      gtmOnPageland(store)
    }
  }, [data.loading, currentUserId, currentOrg, store])

  useEffect(() => {
    updateSalaryBracketModalState("store")
  }, [])

  return (
    <StoreContext.Provider value={data}>
      <>
        <div className="global-main-container--gray">
          {windowStatus.status === "windowActive" && (
            <ColorStrip>
              <TopBenefitWindowInfo windowStatus={windowStatus} />
            </ColorStrip>
          )}
          <Container>
            <div className="topbar-container">
              {/* seems H&E store shows sort filters in both mobile and esktop view, so should default to true */}
              <Topbar
                showTopFilters={true}
                isLifestyleFilters={false}
                loading={data.loading}
                totalEntries={data.totalEntries}
                schemeType={CycletoWorkStore}
              />
              {params.schemeType === "cycle_to_work" && data.quote_limit ? (
                <CycleToWorkMessage
                  benefit={data.benefit_name}
                  quoteLimit={data.quote_limit}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={classNames("store-container", {
                "store-container--mobile": !isDesktop,
              })}
            >
              {isDesktop && <Sidebar />}

              <ProductCardGrid isCycleToWork={CycletoWorkStore} />
            </div>
          </Container>
          {/* {specialPromotionAvailable(specialPromotion) && (
            <StickyPromoPopup
              showBelow={200}
              specialPromotion={specialPromotion}
            />
          )} */}
          <StickyACButton showOnMobile />
          {isDesktop && <ScrollToTop showBelow={500} />}
        </div>
        {(supplierMobileBanner || supplierDesktopBanner) && (
          <BottomBanner
            mobileBannerUrl={supplierMobileBanner}
            desktopBannerUrl={supplierDesktopBanner}
          />
        )}
      </>
    </StoreContext.Provider>
  )
}
