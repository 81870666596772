import InfoTooltip from "../../shared/InfoTooltip"
import Item, { ProductType } from "../basket-page/Item"
import { BannerType } from "../BottomBanner"
import { useContext } from "react"
import { StoreLayoutContext } from "../../layout/store/StoreLayoutContext"
import { getDynamicInfoToolTip } from "../../../utils/dynamicMessageSchemeStores"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export type OrderSummaryProps = {
  products: ProductType[]
  monthlyReduction: number
  term: number
  pricePerMonth: number
  supplierBanner?: BannerType
  totalSavings?: number
}

export default function OrderSummary({
  products,
  monthlyReduction,
  term,
  pricePerMonth,
  supplierBanner,
  totalSavings,
}: OrderSummaryProps) {
  const { quoteType } = useContext(StoreLayoutContext)
  const isNet = quoteType == "NET"
  const basketItemCount = products.reduce(
    (result: number, current: ProductType) => current.quantity + result,
    0
  )
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const params = useParams()
  const { t, ready } = useTranslation("checkoutPage")

  if (!ready) {
    return null
  }
  return (
    <div className="order-summary">
      <div className="order-summary__content">
        <h3>{t("checkoutPage.orderSummary.orderSummaryTitle")}</h3>
        {products.map((product) => (
          <Item key={product.id} product={product} editable={false} />
        ))}
        <div className="order-summary__details">
          <div
            style={{
              marginBottom:
                params.schemeType === "cycle_to_work" ? "" : "1.25rem",
            }}
          >
            <h4>
              {term} {t("checkoutPage.orderSummary.monthTerm")}
            </h4>
            <p>
              {t("checkoutPage.orderSummary.takeHomePay")} (
              {basketItemCount + " "}
              {basketItemCount === 1
                ? t("checkoutPage.orderSummary.item")
                : t("checkoutPage.orderSummary.items")}
              ):
            </p>

            <p>
              <span
                className="h3"
                style={{ color: theme.palette.secondary.main }}
              >
                £{pricePerMonth.toFixed(2)}
              </span>{" "}
              {t("checkoutPage.orderSummary.perMonth")}
              {!isNet && params.schemeType !== "bike_shop" ? (
                <InfoTooltip content={getDynamicInfoToolTip(quoteType)} />
              ) : (
                ""
              )}
            </p>
          </div>
          {params.schemeType === "cycle_to_work" ? (
            <Grid textAlign={"left"} marginBottom={"1.5rem"}>
              <Grid
                sx={{
                  display: isDesktop ? "" : "flex",
                  justifyContent: isDesktop ? "" : "center",
                }}
              >
                <Grid>
                  {" "}
                  <div
                    style={{
                      fontSize: "1rem",
                      color: "#488200",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingTop: "10px",
                    }}
                  >
                    {t("checkoutPage.orderSummary.youCouldSave")} &nbsp;
                  </div>
                </Grid>
                <Grid>
                  {" "}
                  <div
                    style={{
                      fontSize: "24px",
                      color: "#488200",
                      fontWeight: "bold",
                    }}
                  >
                    £ {totalSavings && totalSavings.toFixed(2)}*
                  </div>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: isDesktop ? "" : "flex",
                  justifyContent: isDesktop ? "" : "center",
                }}
              >
                <div
                  style={{
                    fontSize: isDesktop ? "1rem" : "12px",
                    color: "#488200",
                    fontWeight: "normal",
                  }}
                >
                  {t("checkoutPage.orderSummary.acrossYour")} {term}{" "}
                  {t("checkoutPage.orderSummary.acrossMonthTerm")}
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <p>
            {" "}
            {!isNet && params.schemeType !== "bike_shop"
              ? t("checkoutPage.orderSummary.monthlyReduction")
              : t("checkoutPage.orderSummary.monthlyDeduction")}
          </p>
          <p>
            £{monthlyReduction.toFixed(2)}{" "}
            {t("checkoutPage.orderSummary.asSeenPaySlip")}
          </p>

          {!isNet ? (
            <p className="body-3">* {getDynamicInfoToolTip(quoteType)}</p>
          ) : (
            ""
          )}
        </div>
      </div>

      {supplierBanner?.mobileBannerUrl && (
        <div className="order-summary__supplier-banner">
          <img
            src={supplierBanner.mobileBannerUrl}
            alt={t("checkoutPage.orderSummary.supplierBanner")}
          />
        </div>
      )}
    </div>
  )
}
