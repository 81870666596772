import { Box, Link } from "@mui/material"
import { useParams } from "react-router-dom"

export type LogosectionProps = {
  logo: string
  organisationName: string | undefined
  isSingleBenefit?: boolean
  invalidSingleBenefitScheme?: boolean
}

export default function Default_Logosection({
  logo,
  organisationName,
  isSingleBenefit,
  invalidSingleBenefitScheme,
}: LogosectionProps) {
  const params = useParams()

  const link = isSingleBenefit
    ? `/organisations/${params.organisationId}/employee/benefits/home_and_electronics/landing`
    : `/organisations/${params.organisationId}/employee/dashboard`
  return (
    <>
      <Box
        component="div"
        sx={{ height: "6.25rem", display: "table" }}
        data-cy="header-logo-default"
      >
        {!invalidSingleBenefitScheme && (
          <Link
            href={link}
            component={Link}
            sx={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <Box
              component="img"
              sx={{
                maxWidth: "10.625rem",
                maxHeight: "4.852rem",
                paddingLeft: { xs: 3, sm: 4, md: 6 },
                paddingBottom: 1,
              }}
              alt={
                organisationName !== undefined
                  ? `${organisationName} logo`
                  : "Vivup logo"
              }
              src={logo}
            ></Box>
          </Link>
        )}
      </Box>
    </>
  )
}
