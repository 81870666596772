import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import defaultLogo from "../images/logo.png"
import FooterIcons from "./FooterIcons"
import { Link } from "@mui/material"
import useIsMobileView from "../../../../utils/useIsMobileView"
import { useParams } from "react-router-dom"

const FooterLogoSection = ({
  logo = defaultLogo,
  singleBenefit = false,
}: {
  logo?: string
  singleBenefit?: boolean
}) => {
  const isMobile = useIsMobileView()
  const params = useParams()
  const homeLink = singleBenefit
    ? `/organisations/${params.organisationId}/employee/benefits/home_and_electronics/landing`
    : "/" // change from hardcode to dynamic if single journey expands to other benefits other than HE

  return (
    <Grid
      item
      xs={12}
      md={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      data-cy="footer-logo"
    >
      {isMobile ? (
        <>
          <Grid
            item
            xs={4}
            sx={{
              padding: 1,
            }}
          >
            <Link href={homeLink}>
              <Box
                component="img"
                sx={{
                  width: "5.456rem",
                  maxWidth: "6.25rem",
                }}
                alt="Vivup-logo"
                src={logo}
              ></Box>
            </Link>
          </Grid>
          <Grid
            item
            xs={8}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
              padding: 1,
            }}
          >
            <FooterIcons />
          </Grid>
        </>
      ) : (
        <Link href={homeLink}>
          <Box
            component="img"
            sx={{
              width: "7.625rem",
              maxWidth: "7.625rem",
            }}
            alt="Vivup-logo"
            src={logo}
          ></Box>
        </Link>
      )}
    </Grid>
  )
}

export default FooterLogoSection
