import { Button, MenuItem, TextField, styled, useTheme } from "@mui/material"
import Modal from "./Modal"
import { useLayoutEffect, useState } from "react"
import {
  SalaryBracket as SalaryBracketType,
  useGetSalaryBracketsDataQuery,
  useSetSalaryBracketMutation,
} from "../../graphqGenaretedTypes"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function SalaryBracketModal({
  open,
  setOpen,
  schemeType,
}: {
  open: boolean
  setOpen: (state: boolean) => void
  schemeType: string
}) {
  const theme = useTheme()
  const [salaryBrackets, setSalaryBrackets] = useState<SalaryBracketType[]>([])
  const [bracket, setBracket] = useState("")
  const [error, setError] = useState(false)
  const { organisationId } = useParams()
  const isSchemeTypePayRollGiving = schemeType.includes("payroll_giving")
  const [setSalaryBracketMutation] = useSetSalaryBracketMutation({
    variables: {
      salaryBracketId: bracket,
    },
  })

  const { refetch } = useGetSalaryBracketsDataQuery({ errorPolicy: "all" })

  useLayoutEffect(() => {
    refetch().then((queryData) => {
      const currentSalaryBrackets = queryData.data.salaryBrackets
      if (currentSalaryBrackets) {
        setSalaryBrackets(currentSalaryBrackets)
      }
    })
  }, [refetch])

  const handleSubmit = () => {
    if (bracket === "") {
      setError(true)
    } else {
      setSalaryBracketMutation().then(() => {
        setOpen(false)
        window.location.assign(
          !isSchemeTypePayRollGiving
            ? `/organisations/${organisationId}/employee/benefits/${schemeType}/landing`
            : `/organisations/${organisationId}/employee/benefits/${schemeType}/enter_payroll_giving_quote`
        )
      })
    }
  }

  const handleClose = () => {
    if (bracket === "") {
      setError(true)
    }
  }

  const { t, ready } = useTranslation("portalHomepage")

  if (!ready) {
    return null
  }

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      showCloseIcon={false}
      showCloseWindowButton={false}
    >
      <div className="content-center flex-column w-100">
        <h3>{t("salaryBracketModal.payrollPay")}</h3>
        <Message>
          {t("salaryBracketModal.messageOne")} <br />
          <br />
          {t("salaryBracketModal.messageTwo")}
        </Message>
        <label htmlFor="salaryBracket">
          <span className="d-none">
            {t("salaryBracketModal.salaryBracket")}
          </span>
        </label>
        <TextField
          select
          SelectProps={{
            MenuProps: {
              BackdropProps: {
                invisible: true,
              },
              disableScrollLock: true,
            },
          }}
          InputProps={{ id: "salaryBracket" }}
          label="Salary bracket"
          value={bracket}
          error={error}
          helperText={error ? "Please select your salary bracket" : ""}
          onChange={(e) => setBracket(e.target.value)}
          sx={{
            width: { xs: "70%", md: "24rem" },
          }}
        >
          {salaryBrackets.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ color: theme.palette.grey[700] }}
            >
              {option.description}
            </MenuItem>
          ))}
        </TextField>

        <Continue variant="gradient" onClick={handleSubmit}>
          {t("salaryBracketModal.continue")}
        </Continue>
      </div>
    </Modal>
  )
}

const Message = styled("p")(
  ({ theme }) => `
  text-align: center;
  color: ${theme.palette.primary.main};
  width: 85%;
  margin-bottom: 2.9rem;
  margin-top: 1.68rem;
  ${theme.breakpoints.up("md")} {
    width: 28.125rem;
  }
`
)

const Continue = styled(Button)`
  width: 15.75rem;
  margin-top: 3.56rem;
  margin-bottom: 1.5rem;
`
